import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapVictoria,
  locationMap,
  mobileMap,
  mobileMapVictoria,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'wqeNUYWIZap3MBs8cQTq') {
          locationLink = 'https://goo.gl/maps/oRjv3YRTLZaACFGs6'
          mapImage = locationMap
          mapImageMobile = mobileMap
        } else {
          locationLink = 'https://goo.gl/maps/dz7bnQpU6esjsZUU6'
          mapImage = locationMapVictoria
          mapImageMobile = mobileMapVictoria
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Samurai Sushi Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Samurai Sushi Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
