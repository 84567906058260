import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { foodGallery, mobileGallery } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mb="20px">
          <CFImage src={mobileGallery} w="95%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="40px auto" w="90%">
          <CFImage src={foodGallery} alt="Food Gallery" w="100%" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
