import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  CFIcon,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryModal,
} from 'components'
import { Locations } from 'pages'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!dispatch.restaurant.getSelectedLocationId()) {
      if (isMobile) {
        history.push('/locations')
      } else {
        setShowModal(true)
      }
    }
  }, [history, isMobile])

  const navigateLocation = () => {
    if (isMobile) {
      history.push('/locations')
    } else {
      setShowModal(true)
    }
  }

  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="45px"
          w="100%"
          br="30px"
          bc="white"
          bw="2.5px"
          ph="12px"
          center
          onClick={navigateLocation}
        >
          <CFIcon icon="map-marker-alt" color="white" fontSize={22} />
          <CFView white bold mh="10px" mb="2px">
            <CFSelect selector={dispatch.restaurant.getLocationName} />
          </CFView>
          <CFIcon icon="angle-down" color="white" fontSize={30} />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFSelect selector={dispatch.restaurant.getLocationName}>
          <CFView
            center
            br="20px"
            bc="white"
            bw="2px"
            pv="1px"
            ph="15px"
            pointer
            onClick={navigateLocation}
            hover
            zIndex={99}
          >
            <CFView color="white" bold mr="10px" mb="2px">
              <CFSelect selector={dispatch.restaurant.getLocationName} />
            </CFView>
            <CFIcon icon="angle-down" color="white" fontSize={28} />
          </CFView>
        </CFSelect>
      </DefaultScreen>
      <PrimaryModal title="Locations" isOpen={showModal}>
        <Locations onRequestClose={() => setShowModal(false)} />
      </PrimaryModal>
    </CFView>
  )
}
